import React from 'react'
import { NavLink } from "react-router-dom";
import { Menu, Icon, Dropdown } from 'antd';

import './index.less'

export default class NavBar extends React.Component {
  constructor(props) {
    super(props) 
    this.state = {
      isScrolled: false,
      disabledMenu: true,
      showMenu: false
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll.bind(this));
  }

  handleScroll(e) {
    const scrollTop = (e.srcElement ? e.srcElement.documentElement.scrollTop : false) 
    || window.pageYOffset
    || (e.srcElement ? e.srcElement.body.scrollTop : 0);

    this.setState({
      isScrolled: scrollTop
    })
  }

  hanldeAcvive = (match, location) => {
    if (!match) {
      return false
    }
    return match.url === location.pathname
  }

  handleClickIcon = () => {
    this.setState(state => ({
      showMenu: !state.showMenu,
      disabledMenu: false
    }))
  }

  handleOnVisibleChange = (visible) => {
    if (!visible) {
      this.setState({
        disabledMenu: true
      })
    }
  }

  handleClickMenu = (item, key, keyPath, domEvent) => {
    this.setState({
      showMenu: false,
      disabledMenu: true
    })
  }

  handleClickButton = () => {
    this.props.showModal()
    this.setState({
      showMenu: false,
    })
  }

  render() {
    const activeStyle = {
      color: '#D8D8D8'
    }
    const { href } = this.props
    const { isScrolled } = this.state
    const logoSrc = isScrolled ? 'images/nav-black-logo.png': 'images/nav-white-logo.png'

    const iconStyle = {
      color: isScrolled ? '#000' : '#fff',
      fontSize: 28,
      marginRight: 18
    }

    const iconType = this.state.showMenu ? 'close' : 'menu'

    const NavItems = (
      <div className='right'>
        <div className={ isScrolled ? 'nav nav-scroll' : 'nav'}>
          <NavLink to='/' activeStyle={activeStyle} isActive={this.hanldeAcvive}>首页</NavLink>
        </div>
        <div className={ isScrolled ? 'nav nav-scroll' : 'nav'}>
          <NavLink to='/case' activeStyle={activeStyle} isActive={this.hanldeAcvive}>案例</NavLink>
        </div>
        <div className={ isScrolled ? 'nav nav-scroll' : 'nav'}>
          <a href={href} target='_blank' alt='资讯' rel="noopener noreferrer">资讯</a>
        </div>
      </div>
    )

    const menu = (
      <Menu theme='white' onClick={this.handleClickMenu}>
        <Menu.Item key='0'>
          <NavLink to='/' activeStyle={activeStyle} isActive={this.hanldeAcvive}>首页</NavLink>
        </Menu.Item>
        <Menu.Item key='1'>
          <NavLink to='/case' activeStyle={activeStyle} isActive={this.hanldeAcvive}>案例</NavLink>
        </Menu.Item>
        <Menu.Item key='2'>
          <a href={href} target='_blank' alt='资讯' rel="noopener noreferrer">资讯</a>
        </Menu.Item>
      </Menu>
    )

    return(
      <Dropdown 
        disabled={this.state.disabledMenu} 
        overlay={menu} 
        placement='topCenter' 
        trigger={['click']}
        visible={this.state.showMenu}
        onVisibleChange={this.handleOnVisibleChange}
        getPopupContainer={() => document.getElementById('nav')}
      >
        <div id='nav' className={ isScrolled ? 'navbar navbar-scroll' : 'navbar' }>
            <div className='logo'>
              <NavLink to='/'>
                <img src={logoSrc} alt='logo' />
              </NavLink>
            </div>
            {
              // this.state.isMobile ? null : NavItems
              NavItems
            }
            <div className='btn' onClick={this.handleClickButton}>提交需求</div>
            {
              // this.state.isMobile ? (
              //   <Icon type={iconType} style={iconStyle} onClick={this.handleClickIcon}/>
              // )  : null
              <Icon id='icon' type={iconType} style={iconStyle} onClick={this.handleClickIcon}/>
            }
          </div>
      </Dropdown>
    )
  }
}