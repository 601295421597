import React from 'react'
import { Modal } from 'antd'
import './index.less'


export default class SuccessModal extends React.Component {
  render() {
    const { visible, onCancel } = this.props
    return(
      <Modal
        width={660}
        visible={visible}
        footer={null}
        onCancel={onCancel}
        mask={true}
        maskClosable={false}
        centered={true}
      >
        <div className='success-content'>
          <img src='images/success-icon.png' alt='success-icon' />
          <div className='title'>提交成功</div>
          <div className='description'>
            <p>您的需求已提交，</p>
            <p>我们将在24小时内与你联系，深入聊聊需求</p>
            <p>感谢您使用点占服务！</p>
          </div>
          <div className='close-btn' onClick={onCancel}>关闭</div>
        </div>
      </Modal>
    )
  }
}