import React from 'react'
import './index.less'

export default class Advantage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      bottom0: 0,
      bottom1: 0,
      bottom2: 0,
    }
  }

  handleWindowResize() {
    if (this.cardElement0 && this.cardElement1 && this.cardElement2) {
      const cardHeight0 = this.cardElement0.clientHeight;
      const cardHeight1 = this.cardElement1.clientHeight;
      const cardHeight2 = this.cardElement2.clientHeight;
      const coverHeight = window.innerWidth * 0.66; //this.coverElement.clientHeight;
  
      this.setState({
        bottom0: Math.abs(cardHeight0 - (coverHeight - 170) + 20),
        bottom1: Math.abs(cardHeight1 - (coverHeight - 170) + 20),
        bottom2: Math.abs(cardHeight2 - (coverHeight - 170) + 20),
      })  
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize.bind(this))
    setTimeout(() => {
      this.handleWindowResize()
    }, 200)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize.bind(this))
  }

  render() {
    const advantages = [{
      src: 'images/advantage/advantage-01.png',
      title: '您的互联网领域专家，一体化解决方案',
      description: '点占雇佣来自全国各中心城市互联网领域专家，并结合您的需求背景与业务模式，通过用户洞察、产品梳理、设计实施、方案实施组成一体化解决方案，让您的业务模式高效落地。'
    }, {
      src: 'images/advantage/advantage-02.png',
      title: '持续迭代与交付，同步您的业务演进',
      description: '良好的软件产品在整个生命周期中，将不断根据市场与用户反馈而演进，点占通过严谨的流程管理与目标控制，通过持续迭代与交付，保障软件研发与业务目标同步；为您在瞬息万变的市场中赢得先机。'
    }, {
      src: 'images/advantage/advantage-03.png',
      title: '属于您的云团队，资源开销更有弹性',
      description: '随着产业细分，企业无需要费时费力自建研发团队。点占服务方式就如自建团队一般专业与灵活；而与自建研发团队相比，我们将与您一道，根据市场情况制定开发预算，随时调整团队规模，让您每分投入都物尽其用。'
    }]

    const advt0 = advantages[0]
    const advt1 = advantages[1]
    const advt2 = advantages[2]

    const { bottom0, bottom1, bottom2 } = this.state

    return (
      <div className='advantage'>
        <div className='header'>
          我们的优势
        </div>
        <div className='advantage-box advantage-00'>
          <img src={advt0.src} alt='img' className='cover' style={{ marginBottom: bottom0 }} 
          />
          <div className='card' ref={(element) => this.cardElement0 = element}>
            <div className='title'>{advt0.title}</div>
            <div className='description'>{advt0.description}</div>
          </div>
        </div>
        <div className='advantage-box advantage-01'>
          <img src={advt1.src} alt='img' className='cover' style={{ marginBottom: bottom1 }} />
          <div className='card' ref={(element) => this.cardElement1 = element}>
            <div className='title'>{advt1.title}</div>
            <div className='description'>{advt1.description}</div>
          </div>
        </div>
        <div className='advantage-box advantage-02'>
          <img src={advt2.src} alt='img' className='cover' style={{ marginBottom: bottom2 }} />
          <div className='card' ref={(element) => this.cardElement2 = element}>
            <div className='title'>{advt2.title}</div>
            <div className='description'>{advt2.description}</div>
          </div>
        </div>
      </div>
    )
  }
}