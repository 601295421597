import React from 'react'
import { Divider, BackTop } from 'antd';
import { Link } from "react-router-dom";

import './index.less'

export default class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isScrolled: false
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll.bind(this));
  }

  handleScroll(e) {
  }

  backToTop = () => {
    console.log('xx')
    // document.body.scrollTo = 0
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className="footer">
        <div className="contact">
          联系
        </div>
        <div className="email">
          <img src="images/footer/email.png" alt='icon' />
          <div className="text">support@dianzhantech.com</div>
        </div>
        <div className="location">
          <img src="images/footer/location.png" alt='icon' />
          <div className="text">深圳市福田区福华三路财富大厦Bee+</div>
        </div>
        <div className="qrcode">
          <img src="/images/footer/qrcode.png" alt='icon' />
          <p>扫码了解更多</p>
        </div>
        <div className="bottom">
          <div className="left">
            <BackTop visibilityHeight={0}>
              <span>回到顶部</span>
            </BackTop>
            <Divider type="vertical" />
            <Link to='/case'>
              <span>精选案例</span>
            </Link>
          </div>
          <div className="copyright">© 2017-2021 深圳市点占科技有限公司 | <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备17102091号</a></div>
        </div>
      </div>
    )
  }
}
