import React from 'react'

import './index.less'

export default class ServiceProcess extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      src: 'images/process.png'
    }
  }

  windowResize() {
    this.handleWindowResize()
  }

  componentDidMount() {
    window.addEventListener('resize', this.windowResize.bind(this))
    this.handleWindowResize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResize.bind(this))
  }

  handleWindowResize() {
    const src = window.innerWidth < 576 ? 'images/mobile/process.png' : 'images/process.png'
    this.setState({
      src
    })
  }

  render() {
    const { src } = this.state

    return (
      <div className="process">
        <div className="title">服务流程</div>
        <div className='content'>
          <img src={src} alt='img' />
        </div>
      </div>
    )
  }
}