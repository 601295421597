import request from '../utils/request'

// 获取头图列表
export function getBannerList(params = {}) {
  return request({
    url: '/banner',
    method: 'GET',
    params: params
  })
}

// 获取案例列表
export function getCaseList(params = {}) {
  return request({
    url: '/case',
    method: 'GET',
    params: params
  })
}

// 获取团队列表
export function getTeamList(params = {}) {
  return request({
    url: '/team/personnel',
    method: 'GET',
    params
  })
}

// 提交需求
export function submitRequirement(data, params) {
  return request({
    url: '/requirement',
    method: 'POST',
    data,
    params
  })
}

// 获取短信验证码
export function getVerifyCode(params) {
  return request({
    url: '/requirement/verificationcode/sms',
    method: 'GET',
    params
  })
}