import React from 'react'
import { Carousel } from 'antd';
// import { HashLink as Link } from 'react-router-hash-link'; 
import { Link } from "react-router-dom";

import './index.less'

export default class Slicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      height: 600
    }
  }

  handlePrev = () => {
    this.slider.prev()
  }

  handleNext = () => {
    this.slider.next()
  }

  handleHeight () {
    const height = (600/1140) * window.innerWidth
    this.setState({
      height
    })
  }

  windowResize() {
    this.handleHeight()
  }

  componentDidMount () {
    window.addEventListener('resize', this.windowResize.bind(this))
    this.handleHeight()
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.windowResize.bind(this))
  }

  render() {
    const { banners } = this.props
    const { height } = this.state

    return(
      <div className='slider' style={{height}}>
        <Carousel
          autoplay 
          effect='fade'
          ref={node => (this.slider = node)}
        >
          {
            banners.map(item => (
              <Link key={item.id} to={ item.case_status ? `case#${item.case_id}` : '' }>
                <img src={item.url} alt='' style={{height}}/>
              </Link>
            ))
          }
        </Carousel>
        <div className='left-icon' onClick={this.handlePrev}>
          <img src='images/left-icon.png' alt='left-icon' />
        </div>
        <div className='right-icon' onClick={this.handleNext}>
          <img src='images/right-icon.png' alt='right-icon' />
        </div>
      </div>
    )
  }
}