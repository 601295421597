import React from 'react'
import { Form, Modal, Input, Button, message, Row, Col } from 'antd'
import {
  getVerifyCode
} from '../../service/index'
import './index.less'

const { TextArea } = Input;

const TIME_LIMIT = 60;

class SubmitModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobile: '',
      isMobileValid: false, // 手机号码是否为11位
      sending: false,
      time: TIME_LIMIT,
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      this.props.handleSubmit(err, values)
    });
  };

  componentDidMount() {
    this.props.onRef(this)
  }

  handleResetFields = () => {
    console.log('从父组件中调用了哟')
    this.props.form.resetFields()
  }

  handleMobileChange = (e) => {
    e.preventDefault()
    const mobile = e.currentTarget.value
    
    this.setState({
      mobile: mobile
    })

    if (mobile && mobile.length === 11) {
      this.setState({
        isMobileValid: true
      })
    } else {
      this.setState({
        isMobileValid: false
      })
    }
  }

  handleGetCode = async () => {
    const { mobile } = this.state;
    console.log('mobile', mobile)
    const response = await getVerifyCode({ mobile: mobile })
    if (response.message === '发送短信验证码成功') {
      message.info('验证码已发送，请注意查收')
      this.process()
    }
  }

  process = () => {
    const { time } = this.state;
    if (time === 0) {
      this.setState({
        sending: false,
        time: TIME_LIMIT,
      })
      return;
    }
    else {
      setTimeout(() => {
        this.setState((state) => ({
          sending: true,
          time: state.time - 1,
        }))
        this.process();
      }, 1000)
    }
  }

  render() {
    const { visible, onCancel } = this.props
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 0 }, // 没有效果，通过media query 隐藏 
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
      labelAlign: 'left',
      colon: false,
      hideRequiredMark: true
    };

    const butText = this.state.sending ? `请${this.state.time}秒后再试` : '获取验证码';
 

    return (
      <Modal 
        width={660}
        visible={visible}
        footer={null}
        onCancel={onCancel}
        mask={true}
        maskClosable={false}
        centered={true}
      >
        <div className='content'>
          <Form {...formItemLayout}>
            <div className='title'>提交需求</div>
            <div className='description'>填写需求，获取产品咨询与方案报价</div>
            <div className='form'>
              <Form.Item label='联系人'>
                {getFieldDecorator('name', {
                  rules: [
                    { required: true, message: '请输入您的姓名' },
                    { max: 20, message: '输入内容过长'}
                  ],
                })(<Input placeholder='如何称呼您' />)}
              </Form.Item>
              <Form.Item label='电话'>
                {getFieldDecorator('mobile', {
                  rules: [
                    { required: true, message: '请输入您的手机号码' },
                    { max: 11, message: '请输入合法的手机号码'}
                  ],
                })(<Input placeholder='手机号码' onChange={this.handleMobileChange} />)}
              </Form.Item>
              <Form.Item label='验证码' wrapperCol={{ xs: {span: 12} }}>
                <Row gutter={8}>
                  <Col span={12}>
                    {getFieldDecorator('code', {
                      rules: [
                        { required: true, message: '验证码' },
                        { max: 50, message: '输入内容过长'}
                      ],
                    })(<Input placeholder='验证码' />)}
                  </Col>
                  <Col span={12}>
                    <Button type="primary" disabled={this.state.sending || !this.state.isMobileValid } onClick={this.handleGetCode}>{butText}</Button>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label='微信'>
                {getFieldDecorator('wechatid', {
                  rules: [{
                    max: 100, message: '输入内容过长'
                  }]
                })(<Input placeholder='方便能快速联系到您（可选填）' />)}
              </Form.Item>
              <Form.Item label='需求描述'>
                {getFieldDecorator('description', {
                  rules: [
                    { required: true, message: '请简要描述您的需求' },
                    { max: 400, message: '输入内容过长'}
                  ],
                })(<TextArea rows={4} wrap="hard" placeholder='请简要描述您的需求。（示例：我想做一款小程序，需要有在线支付，评价反馈，可参考企鹅优品。）' />)}
              </Form.Item>
            </div>
            <div className='btn' onClick={this.handleSubmit}>提交需求</div>
          </Form>
        </div>
      </Modal>
    )
  }
}

export default Form.create({name: 'form'})(SubmitModal)