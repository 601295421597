import React from 'react'
import { Row, Col } from 'antd'

import './index.less' 

export default class Feature extends React.Component {
  render() {

    const features = [{
      id: 0,
      icon: 'images/feature/feature-01.png',
      mobileIcon: 'images/mobile/f-01.png',
      title: '产品孵化与探索',
      description: '全方位了解业务需求背景，帮助企业策划产品形态，并基于最佳实践快速交付。'
    }, {
      id: 1,
      icon: 'images/feature/feature-02.png',
      mobileIcon: 'images/mobile/f-02.png',
      title: '持续交付与运维',
      description: '软件价值始于持续服务，基于业务目的制定关键指标，并通过Devops持续迭代。'     
    }, {
      id: 2,
      icon: 'images/feature/feature-03.png',
      mobileIcon: 'images/mobile/f-03.png',
      title: '数字运营与增长',
      description: '透过数据来洞察用户需求，围绕业务核心竞争力构建增长方案。'
    }]

    const itemStyles = [{
      backgroundColor: '#F7F7F7'
    }, {
      backgroundColor: '#EFEFEF'
    }, {
      backgroundColor: '#E8E8E8'
    }]

    return(
      <div className="feature">
        <div className='m-feature-body'>
          {
            features.map(item => (
              <div className='m-feature-item' key={item.id}>
                <img src={item.mobileIcon} alt={item.id} />
                <p className='title'>{item.title}</p>
              </div>
            ))
          }
        </div>
        <div className="feature-body">
          <Row type='flex' justify='space-between'>
            {
              features.map(item => (
                <Col sm={8} xs={24} key={item.id}>
                  <div className="feature-item" style={itemStyles[item.id]}>
                    <img src={item.icon} alt={item.id} />
                    <p className="title">{item.title}</p>
                    <p className="description">{item.description}</p>
                  </div>
                </Col>
              ))
            }
          </Row>
        </div>
      </div>
    )
  }
}