import React from 'react'
import { Spin, Icon } from 'antd'
import moment from 'moment'
import NoMore from '../NoMore'
import {
  getCaseList
} from '../../service'
import './index.less'

export default class Case extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      page_size: 5,
      spinning: false,
      done: false,
      isMobile: false,
      cases: []
    }
  }

  handleLoadMore = async () => {
    const { page, page_size } = this.state

    this.setState(state => ({
      page: state.page + 1,
      spinning: true
    }))

    const response = await getCaseList({
      page: page + 1,
      page_size,
      sort_name: 'display_order',
      sort_mode: 0
    })

    if (response && response.message === 'success') {
      if (response.data.data.length === 0) {
        this.setState({ spinning: false, done: true })
        return
      }

      this.setState(state => ({
        cases: state.cases.concat(response.data.data)
      }))
    }

    this.setState({ spinning: false })
  }

  fetchCaseList = async () => {
    const { page, page_size } = this.state
    const response = await getCaseList({
      page,
      page_size,
      sort_name: 'display_order',
      sort_mode: 0
    })
    if (response && response.message === "success") {
      this.setState({
        cases: response.data.data
      })
      setTimeout(() => {
        this.handleScroll()
      }, 250)
    }
  }

  handleScroll = () => {
    const { hash } = window.location
    console.log('hash', hash)
    const element = document.getElementById(hash.slice(1))
    console.log('element', element)

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }

  async componentWillMount() {
    await this.fetchCaseList()
  }

  componentDidMount() {
    // 处理SEO
    document.getElementsByTagName("title")[0].innerText = '点占科技案例 | 不止于产品交付';

    const keywords = document.getElementsByTagName("meta")[1];
    keywords.content = '点占科技案例,点占服务案例,网站开发案例,小程序开发案例,app开发案例,软件开发案例,产品咨询,专业软件定制开发,点赞,软件项目需求'

    const description = document.getElementsByTagName("meta")[2];
    description.content = '产品是商业模式的容器，我们善于将您的商业逻辑有序的通过产品形态传达给目标用户。'

    const isMobile = window.innerWidth < 576 ? true : false
    this.setState({
      isMobile
    })

    // setTimeout(() => {
    //   this.handleScroll()
    // }, 500)
  }

  componentDidUpdate() { }

  render() {
    // 奇数
    const oddStyle = {
      top: '5%',
      right: '10%',
    }

    // 偶数
    const evenStyle = {
      top: '5%',
      left: '10%',
    }

    const ladingIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

    const loadMore = this.state.spinning ?
      <Spin indicator={ladingIcon} spinning={this.state.spinning} />
        :
      <Icon type='down' style={{ color: '#9DA0A6' }} onClick={this.handleLoadMore} />

    const headImg = this.state.isMobile ? 'images/mobile/case-header.png' : 'images/case-header.png'

    return(
      <div className="case">
        <div className="header">
          <img src={headImg} alt='case-header' />
          <div className='title'>持续迭代实现业务价值</div>
        </div>
        <div className="story">
          <div className='title'>
            案例故事
          </div>
          {
            this.state.cases.map(item => (
                <div className='story-item' key={item.id} id={item.id}>
                  <img src={item.pcimg} alt='story' />
                  <div className='card' style={ item.id % 2 ? oddStyle : evenStyle }>
                    <div className='card-name'>{item.name}</div>
                    <div className='card-title'>{item.title}</div>
                    <div className='card-body'>
                      <div className='card-body-left'>
                        <div className='left-item'>
                          <span className='label'>上线时间：</span>
                          <span className='value'>{moment(item.publish_date).format('YYYY.MM')}</span>
                        </div>
                        {/*<div className='left-item'>*/}
                        {/*  <span className='label'>已迭代次数：</span>*/}
                        {/*  <span className='value'>{item.iteration_frequency}</span>*/}
                        {/*</div>*/}
                        <div className='left-item'>
                          <span className='label'>服务类型：</span>
                          <span className='value'>{item.service_type}</span>
                        </div>
                      </div>
                      <div className='card-body-right'>
                        <img src={item.qrcode} alt='qrcode' />
                      </div>
                    </div>
                    <div className='card-bottom'>{item.description}</div>
                  </div>
                </div>
              ))
          }
          <div className='more'>
            {
              !this.state.done ? loadMore : <NoMore />
            }
          </div>
        </div>
      </div>
    )
  }
}
