import React from 'react'
import './index.less'

export default class NoMatch extends React.Component {
  render() {
    return(
      <div className='no-match'>
        <div className='nav-bg'></div>
        <div className='title'>404</div>
        <div className='info'>未找到该页面</div>
      </div>
    )
  }
}