import axios from 'axios'
import { message } from 'antd'

import { mockServer,productionUrl } from '../config/api'

const { href } = window.location
console.log('location:', window.location)

const service = axios.create({
  baseURL: process.env.NODE_ENV === "development" ? `${mockServer}api/backend/` : `${productionUrl}api/backend/`,
  timeout: 15000
})

service.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    if (error.response && error.response.status) {
      message.error(error.response.data.message)
      return Promise.reject(error.response);
    } else {
      message.error(error.message || "服务器无响应")
    }
    return Promise.reject(error);
  }
);
export default service
