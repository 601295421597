import React from 'react'
import Slicker from '../Slicker'
import Feature from '../Feature'
import Advantage from '../Advantage'
import ServiceProcess from '../ServiceProcess'
import Team from '../Team'
import Enterprise from '../Enterprise'
import {
  getBannerList
} from '../../service'

import './index.less'

export default class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      banners: []
    }
  }
  
  async componentDidMount() {
    // 处理SEO
    document.getElementsByTagName("title")[0].innerText = '点占科技 | 不仅是从0到1';

    const keywords = document.getElementsByTagName("meta")[1];
    keywords.content = 'dianzhan,dianzan,点占,点占科技,定制开发,产品咨询,小程序,app开发,专业软件定制开发,点赞,软件项目需求,软件开发,外包,软件外包,深圳软件外包'

    const description = document.getElementsByTagName("meta")[2];
    description.content = '点占科技是一家为企业提供一体化产品解决方案的互联网公司。从解决方案、产品设计、研发迭代、运营增长， 帮助企业解决实际问题，并借助企业互联网转型，实现数字化，打破技术壁垒。点占依托标准、透明的交付方式，在坚持品质服务的前提下寻求最高效率，为企业创造真实的产品价值。'

    await this.fetchBannerList()
  }

  fetchBannerList = async () => {
    const banners = []
    const response = await getBannerList({
      sort_name: 'display_order',
      sort_mode: 0,
    })
    response.data.forEach(item => {
      banners.push({
        id: item.id,
        case_status: item.case_status,
        url: item.banner_img,
        case_id: item.case_info ? item.case_info.id : ''
      })
    })

    this.setState({ banners })
  }

  componentDidUpdate() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    })
  }

  render() {
    return (
      <div className="home">
        <Slicker banners={this.state.banners} />
        <Feature />
        <Advantage />
        <ServiceProcess />
        <Team />
        <Enterprise />
      </div>
    )
  }
}