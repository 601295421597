import React from 'react'
import { Row, Col } from 'antd'

import './index.less'

export default class Enterprise extends React.Component {
  render() {

    const data = [{
      id: 0,
      path: 'images/enterprise/01.png'
    },{
      id: 1,
      path: 'images/enterprise/02.png'
    }, {
      id: 2,
      path: 'images/enterprise/03.png'
    }, {
      id: 3,
      path: 'images/enterprise/04.png'
    }, {
      id: 4,
      path: 'images/enterprise/05.png'
    }, {
      id: 5,
      path: 'images/enterprise/06.png'
    }, {
      id: 6,
      path: 'images/enterprise/07.png'
    }, {
      id: 7,
      path: 'images/enterprise/08.png'
    }]

    return (
      <div className="enterprise">
        <div className="enterprise-title">合作企业</div>
        <div className="enterprise-body">
          <Row gutter={0}>
            {
              data.map(item => (
                <Col key={item.id} xs={{span: 12}} sm={{span: 8}} md={{span:6}} >
                    <img src={item.path} alt='img' />
                </Col>
              ))
            }
          </Row>
        </div>
      </div>
    )
  }
}