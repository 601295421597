import React from 'react'
import { Row, Col } from 'antd'
import {
  getTeamList
} from '../../service/index'
import './index.less'

export default class Team extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hoverID: -1,
      teams: []
    }
  }

  async componentDidMount() {
    const response = await getTeamList({
      sort_name: 'display_order',
      sort_mode: 0,
    })
    if (response.message === 'success') {
      this.setState({
        teams: response.data
      })
    }
  }

  handleMouseEnter = (e) => {
    const hoverIndex = e.target.getAttribute('hoverindex')
    this.setState({
      hoverID: parseInt(hoverIndex, 10)
    })
  }

  handleMouseLeave = (e) => {
    this.setState({
      hoverID: -1
    })
  }

  render() {

    const { hoverID, teams } = this.state;
    const hoverStyle = {}
    const otherStyle = {
      opacity: .4,
    }

    return (
      <div className="team">
        <div className="title">领域专家</div>
        <div className="team-body">
          <Row type='flex' justify='center'>
            {
              teams.map(item => (
                <Col xs={{span: 12}} sm={{span: 12}} md={{span: 8}} lg={{span:6}} key={item.id}>
                  <div className="team-item">
                    <div className='img-box'>
                      <img 
                        src={item.pc_business_card} 
                        alt='img' 
                        hoverindex={item.id} 
                        onMouseEnter={this.handleMouseEnter} 
                        onMouseLeave={this.handleMouseLeave} 
                        style={ (hoverID === -1) ? hoverStyle : hoverID === item.id ? hoverStyle : otherStyle }
                      />
                    </div>
                    <div className="name">{item.name}</div>
                    <div className="position">{item.position}</div>
                  </div>
                </Col>
              ))
            }
          </Row>
        </div>
      </div>
    )
  }
}