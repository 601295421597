import React from 'react';
import './App.css';

import NavBar from './components/NavBar/index'
import Home from './components/Home/index'
import Case from './components/Case/index'
import Footer from './components/Footer/index'
import SubmitModal from './components/SubmitModal/index'
import SuccessModal from './components/SuccessModal/index'
import NoMatch from './components/NoMatch/index'
import {
  submitRequirement
} from './service'

import { 
  BrowserRouter as Router, 
  Route,
  Switch
} from "react-router-dom";


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitVisible: false,
      successVisible: false,
    }
  }

  handleShowModal = () => {
    this.child.handleResetFields()
    this.setState({
      submitVisible: true
    })
  }

  handleSubmit = async (err, values) => {
    if (!err) {
      console.log('values:', values)
      const data = {
        mobile: values.mobile,
        name: values.name,
        wechatid: values.wechatid,
        description: values.description
      }

      const params = {
        code: values.code
      }

      const response = await submitRequirement(data, params)
      console.log('response:', response)
      if (response.message === '需求提交成功') {
        this.setState({
          submitVisible: false,
          successVisible: true
        })
      }
    }
  }

  handleSuccessCancel = () => {
    this.setState({
      successVisible: false
    })
  }

  handleSumbitCancel = () => {
    this.setState({
      submitVisible: false
    })
  }

  onRef = (ref) => {
    this.child = ref
  }

  componentDidMount() {
  }

  scrollToAnchor = (anchorName) => {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName)
      if(anchorElement) { 
        anchorElement.scrollIntoView()
      }
    }
  }

  render() {
    const href = 'https://www.zhihu.com/org/dian-zhan-ke-ji-83/posts'
    const { submitVisible, successVisible } = this.state
    return (
      <Router>
        <div className="App">
          <NavBar showModal={this.handleShowModal} href={href} />

          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/case" component={Case} />
            <Route component={NoMatch} />
          </Switch>

          <Footer />
          <SubmitModal
            visible={submitVisible} 
            onRef={this.onRef}
            handleSubmit={this.handleSubmit} 
            onCancel={this.handleSumbitCancel} 
          />
          <SuccessModal visible={successVisible} onCancel={this.handleSuccessCancel} />
        </div>
      </Router>    
    );  
  }
}

export default App;
