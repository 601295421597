import React from 'react'
import './index.less'

export default class NoMore extends React.Component {
  render() {
    return(
      <div className='no-more'>
        <div className='line'></div>
        <div className='text'>没有更多了</div>
        <div className='line'></div>
      </div>
    )
  }
}